.root {
  @apply grid cursor-pointer place-items-center shadow-[0_0_1rem_0.1rem_var(--tw-shadow-color)] ring-2 ring-current transition-all;
}

.primary {
  @apply bg-neutral-900 text-orange-200 ring-orange-400;

  & .badge {
    @apply bg-orange-400/50 text-white shadow-orange-200 ring-2 ring-orange-200 backdrop-blur-sm;
  }
  & .loading-ring {
    @apply border-b-[3px] border-b-neutral-100; // group-hover:border-b-current;
  }

  &:hover {
    @apply bg-orange-400/20 text-orange-200 shadow-orange-200 ring-2 ring-orange-400 backdrop-blur-sm;
    & .loading-ring {
      @apply border-b-orange-100;
    }
  }
}
.secondary {
  @apply bg-neutral-900 text-orange-300 ring-purple-600;

  & .badge {
    @apply bg-purple-950 text-purple-100 shadow-purple-300 ring-orange-400;
  }
  & .loading-ring {
    @apply border-b-orange-400;
  }

  &:hover {
    @apply bg-purple-600/20 text-orange-400 shadow-purple-300 ring-2 ring-purple-600 backdrop-blur-sm;
    & .loading-ring {
      @apply border-b-orange-500;
    }
  }
}

.badge {
  @apply absolute z-1 min-w-[1.75em] rounded-full p-[0.15em_0.25em] font-jetbrains text-xs font-thin ring-1;
}
.badge-position {
  &-top-left {
    @apply -left-1.5 -top-1.5;
    @apply group-hover:shadow-[-1px_-1px_6px_0px_var(--tw-shadow-color)];
  }
  &-top-right {
    @apply -right-1.5 -top-1.5;
    @apply group-hover:shadow-[1px_-1px_6px_0px_var(--tw-shadow-color)];
  }
  &-bottom-left {
    @apply -bottom-1.5 -left-1.5;
    @apply group-hover:shadow-[1px_-1px_6px_0px_var(--tw-shadow-color)];
  }
  &-bottom-right {
    @apply -bottom-1.5 -right-1.5;
    @apply group-hover:shadow-[1px_1px_6px_0px_var(--tw-shadow-color)];
  }
}
.loading-ring {
  @apply absolute -inset-0.5 animate-spin rounded-full border-0 border-b-2 border-solid transition;
}
