@import 'src/styles/tailwind/components';

.root {
  @extend .frame, .frame-card;
  @apply fixed z-4 flex flex-col gap-2 shadow-small transition-all;
  @apply bottom-3 left-3 right-3 max-h-screen will-change-[top];
  top: calc(var(--nav-height) - (var(--nav-height) * 0.7 - 0.75rem) * var(--nav-hidden)); //top-3
}

@screen sm {
  .root {
    @apply left-auto right-0 min-w-[24rem] rounded-r-none border-r-0;
  }
}
