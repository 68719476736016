@import 'src/styles/tailwind/components';

.root {
  @extend .frame, .frame-item;
  @apply relative grid flex-full gap-2;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr auto;
}

.header {
  @apply relative flex items-center gap-2 leading-none;
  grid-row-start: 1;
  grid-column: 1 / 4;
}

.image {
  @apply h-full max-h-16 min-h-[3rem] w-full max-w-[3rem] rounded object-cover object-center;
  grid-row-start: 2;
}

.details {
  @apply text-sm text-white/60;
  grid-row-start: 2;
  grid-column-start: 2;
}

.actions {
  @apply flex justify-end gap-1.5 self-end;
  grid-row-start: 2;
  grid-column-start: 3;
}

// Actions button
.action-btn-base {
  @apply rounded-md border border-solid transition-all;
}
.action-btn {
  @extend .action-btn-base;
  @apply border-orange-400/25 text-white hover:border-orange-400;
}
.action-btn-delete {
  @extend .action-btn-base;
  @apply border-red-400/50 text-red-400 hover:text-red-600;
}

//Badge color sets
.badge {
  &-orange {
    &-bg {
      @apply bg-orange-400/10;
    }
    &-mainBorder {
      @apply border border-solid border-orange-600;
    }
    &-subBorder {
      @apply border border-solid border-orange-400 border-l-transparent;
    }
  }
  &-blue {
    &-bg {
      @apply bg-blue-400/10;
    }
    &-mainBorder {
      @apply border border-solid border-blue-600;
    }
    &-subBorder {
      @apply border border-solid border-blue-400 border-l-transparent;
    }
  }
  &-purple {
    &-bg {
      @apply bg-purple-400/10;
    }
    &-mainBorder {
      @apply border border-solid border-purple-600;
    }
    &-subBorder {
      @apply border border-solid border-purple-400 border-l-transparent;
    }
  }
}

// @screen sm {
//   .root {
//   }
//   .details {
//   }
//   .actions {
//   }
// }
