@import 'src/styles/tailwind/components';

.root {
  @extend .frame;
  @apply sticky top-0 z-5 mb-3 flex items-center gap-4 p-2 shadow-small transition-all;

  /* hover:border-purple-400/75 */

  &.slided {
    @apply -translate-y-3/4;

    &:hover {
      @apply -translate-y-2;
    }
  }
}

.svg-path-stroke {
  & > path {
    stroke: inherit;
  }
}

.btn {
  @apply leading-normal text-orange-300 shadow-[0_0_0.75rem_0.075rem_var(--tw-shadow-color)] transition-all;
  @extend .svg-path-stroke;

  &:hover {
    @apply bg-orange-300/10 shadow-orange-400 ring-1 ring-current;
  }
}
.btn-active:not(:hover) {
  @apply text-orange-200 ring-1 ring-orange-200/30; //ring-neutral-600;
}

.UserButton {
  @apply h-10 w-10;
}
.UserMenu {
  @apply right-1 mt-4 sm:right-3 sm:mt-2;
  left: unset !important;
}
.Menu-ChangeView {
  @apply text-purple-300 transition-colors hover:bg-purple-300/10 hover:text-purple-200;

  & .MenuItemIcon {
    @apply ml-[-0.15em];
  }
}
.MenuItemIcon {
  @apply svg-path-stroke text-[1.75em];
}

@screen sm {
  .root {
    @apply top-3 m-3 rounded-md p-3; //sticky top-3
  }
  .UserButton {
    @apply -my-4 -mr-3 h-16 w-16 #{!important};
  }
}
