@layer utilities {
  // Overflow
  .overflow-overlay {
    overflow: overlay;
  }
  .overflow-y-overlay {
    overflow-y: overlay;
  }
  .overflow-x-overlay {
    overflow-x: overlay;
  }

  // Shadow
  .custom-shadow-sm {
    box-shadow:
      0px 0.1px 0.1px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0px 0.2px 0.2px -1.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0.1px 0.6px 0.7px -2.3px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-md {
    box-shadow:
      0.1px 0.4px 0.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0.4px 1.4px 1.7px -0.7px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      1px 3.3px 4.1px -1.4px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      2.4px 7.9px 9.8px -2.1px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-lg {
    box-shadow:
      0.1px 0.4px 0.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0.8px 2.6px 3.2px -0.3px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      1.5px 4.9px 6.1px -0.6px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      2.4px 7.8px 9.7px -0.9px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      3.7px 12.1px 15px -1.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      5.7px 18.6px 23.1px -1.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      8.5px 27.8px 34.4px -1.8px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      12.4px 40.5px 50.2px -2.1px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-sm-dark {
    box-shadow:
      0px 0.1px 0.1px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0px 0.2px 0.2px -1.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      0.1px 0.6px 0.7px -2.3px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-md-dark {
    box-shadow:
      -0.1px 0.6px 0.8px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.3px 2.4px 3.3px -0.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.5px 4.4px 6px -0.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.8px 7.9px 10.7px -0.7px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -1.5px 14.2px 19.3px -1px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }
  .custom-shadow-lg-dark {
    box-shadow:
      -0.1px 0.3px 0.3px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.2px 1.1px 1.2px -0.3px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.3px 1.8px 2px -0.6px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.5px 2.8px 3.1px -0.9px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -0.7px 4.2px 4.6px -1.3px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -1px 6.1px 6.6px -1.6px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -1.5px 8.8px 9.6px -1.9px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -2.1px 12.4px 13.5px -2.2px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -2.9px 17.2px 18.7px -2.5px var(--tw-shadow-color, rgba(0, 0, 0, 1)),
      -4px 23.3px 25.4px -2.8px var(--tw-shadow-color, rgba(0, 0, 0, 1));
  }

  // Drop shadow
  .custom-drop-shadow {
    filter: drop-shadow(5px 5px 13px #ffd33d);
  }

  // Text shadow
  .text-shadow {
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  }
  .text-shadow-dark {
    text-shadow: var(--color-accent) 1px 0 10px;
  }
}
