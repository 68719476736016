.root {
  @apply fixed bottom-6 right-6 transition-all hover:scale-[1.2];
  @apply shadow-[0_0_26px_-2px_var(--tw-shadow-color)];

  &:hover {
    @apply hover:text-orange-500 hover:shadow-current hover:ring-2 hover:ring-current;
    // 'hover:ring-[2px] hover:ring-current', // 'hover:ring-offset-[2px] hover:ring-offset-black/25'
  }

  &.hidden {
    @apply -bottom-10 scale-0 opacity-0;
  }
}
