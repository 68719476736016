@import 'src/styles/tailwind/components';

.paper {
  @apply frame overflow-visible rounded-md p-0;
}
.form-control {
  &:global(:has(.MuiSelect-select) .MuiInputLabel-outlined.MuiInputLabel-sizeMedium:not(.Mui-focused, .MuiFormLabel-filled)) {
    top: -0.45em;
  }
}

.select {
  @apply flex gap-1;
}
.select-icon {
  font-size: 1.25em;
  @apply transition-all;

  &:global(.MuiSelect-iconOpen) {
    @apply fill-orange-400; //rotate-180 scale-125
  }
}
.tooltip {
  @apply border border-white/25 bg-neutral-900;
}
.tooltip-arrow {
  font-size: 0.875rem;
  @apply isolate -z-1 text-neutral-900 before:border before:border-white/25;
}
