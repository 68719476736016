@import 'src/styles/tailwind/components';

.root {
  @apply frame relative flex max-w-md items-center gap-3 whitespace-pre-line rounded-md border pr-6 shadow-small;
  @apply transition will-change-auto;

  // &:hover {
  //   // @apply scale-105;
  //   // @apply ring-2 ring-orange-400/40 border-transparent;
  //   // ring-offset-[20px] ring-offset-yellow-500 border-white border-orange-400
  // }

  & > *:first-child {
    flex-shrink: 0;
  }

  .close {
    @apply absolute -right-1 -top-1 p-1 transition-all;
    @apply text-red-400 shadow-red-500;
    @apply bg-red-950 #{!important};
    border: 1px solid currentColor;

    &:hover {
      @apply scale-110;
      box-shadow: 0 0 0.5rem 0 var(--tw-shadow-color) !important;
    }
  }
}

.icon {
  @apply frame relative -ml-8 max-w-[4rem] rounded p-1.5 text-3xl;

  /* DEV: type variations ➡️ 'success', 'error', 'loading', 'blank', 'custom' */
  &.success {
    @apply bg-green-950 text-green-500;
  }
  &.error {
    @apply bg-red-950 text-red-500;
  }

  svg {
    @apply block;
  }
}
