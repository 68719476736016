@tailwind components;

// Components
@layer components {
  .main-content {
    @apply relative flex-grow px-2 pb-3;

    & > *:first-child:is(.snap-start, .snap-center, .snap-end) {
      @apply scroll-mt-[calc(100dvh_-_var(--nav-height))];
    }
  }

  .frame {
    //DEV: original 'backdrop-blur-sm bg-neutral-900/75', just to increase the perfomance during development
    // p-3
    @apply border border-solid border-neutral-600 bg-neutral-900 bg-none;

    &.frame-card {
      @apply rounded-md p-3;
    }
    &.frame-item {
      @apply rounded bg-white/10 p-2;
    }
  }

  @screen tablet {
    .main-content {
      @apply px-3;
    }
  }
}
