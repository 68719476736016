// @config "../../tailwind.config.js";
@tailwind base; // @import 'tailwindcss/base';
@tailwind components; // @import 'tailwindcss/components';
@tailwind utilities; // @import 'tailwindcss/utilities';
@tailwind variants; // @import 'tailwindcss/variants';

// Globals
@layer base {
  :root {
    /* Inherited */
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;

    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    /* Inherited */
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  html {
    scroll-behavior: smooth;
    // scroll-snap-type: y proximity;
  }
  body {
    @apply overflow-x-hidden bg-background font-roboto text-foreground antialiased;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
    font-size: calc(20px + (40 - 20) * (100vw - 320px) / (2560 - 320));
    // background: url('/imgs/main-bg.jpg');
    // background-position: center;
    // background-size: cover;
    // background-attachment: fixed;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  ul,
  p,
  figure {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  li {
    list-style-type: none;
  }

  .border,
  .border-2,
  .border-4 {
    @apply border-solid;
  }

  // Scroll bar
  ::-webkit-scrollbar {
    @apply w-4;
  }
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  ::-webkit-scrollbar-thumb {
    @apply rounded-full bg-black/30 bg-clip-padding transition-all;
    border: 4px solid transparent;

    &:hover {
      @apply bg-black/50;
    }
  }

  :is(.dark) {
    ::-webkit-scrollbar-thumb {
      @apply bg-orange-400/50;

      &:hover {
        @apply bg-orange-400;
      }
    }
  }
}

@import './tailwind/components';
@import './tailwind/utilities';
